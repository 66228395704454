export const data = {
  en: {
    config: {
      name: 'bahyu-sella',
      bgm: require('./assets/song.mp3'),
    },
    header: {
      bgImageLandscape: [require('./assets/images/moment/ls-1.jpg'), require('./assets/images/moment/ls-2.jpg'), require('./assets/images/moment/ls-3.jpg'),require('./assets/images/moment/ls-4.jpg'), require('./assets/images/moment/ls-5.jpg'), require('./assets/images/moment/ls-6.jpg'),require('./assets/images/moment/ls-7.jpg'), require('./assets/images/moment/ls-8.jpg'), require('./assets/images/moment/ls-9.jpg'),require('./assets/images/moment/ls-10.jpg'),require('./assets/images/moment/ls-11.jpg')],
      bgImagePortrait: [
        require('./assets/images/moment/pt-0.jpg'),
        require('./assets/images/moment/pt-1.jpg'),
        require('./assets/images/moment/pt-2.jpg'),
        require('./assets/images/moment/pt-3.jpg'),
        require('./assets/images/moment/pt-4.jpg'),
        require('./assets/images/moment/pt-5.jpg'),
        require('./assets/images/moment/pt-6.jpg'),
        require('./assets/images/moment/pt-7.jpg'),
        require('./assets/images/moment/pt-8.jpg'),
        require('./assets/images/moment/pt-9.jpg'),
        require('./assets/images/moment/pt-10.jpg'),
        require('./assets/images/moment/pt-11.jpg'),
        require('./assets/images/moment/pt-12.jpg'),
        require('./assets/images/moment/pt-13.jpg'),
        require('./assets/images/moment/pt-14.jpg'),
        require('./assets/images/moment/pt-15.jpg'),
        require('./assets/images/moment/pt-16.jpg'),
        require('./assets/images/moment/pt-17.jpg'),
        require('./assets/images/moment/pt-18.jpg'),
        require('./assets/images/moment/pt-19.jpg'),
        require('./assets/images/moment/pt-20.jpg'),
      ],
      bridegroom: 'Sella & Bahyu',
      desc: 'AKAN MENIKAH',
      date: '3 NOMinggu, 3 November 2024',
      timestamp: 1730566800000,
      ongoing: 'BERLANGSUNG',
      days: 'HARI',
      hours: 'JAM',
      minutes: 'MENIT',
      seconds: 'DETIK',
      line1: 'Kepada Yth: Bapak / Ibu / Saudara',
      line2: 'Kami Mengundang Anda Untuk Merayakan Pernikahan Kami',
      button: 'Buka Undangan',
    },
    navbar: {
      brand: 'S & B',
      nav: [
        { to: 'header', text: 'Beranda' },
        { to: 'couple', text: 'Pasangan' },
        { to: 'events', text: 'Acara' },
        { to: 'moment', text: 'Momen' },
        { to: 'location', text: 'Lokasi' },
        { to: 'gift', text: 'Hadiah' },
        { to: 'greeting-card', text: 'Kartu Ucapan' },
      ],
    },
    couple: {
      title: 'Temui Pasangan Bahagia',
      groom: {
        name: 'Bahyu Sanciko S.Kom',
        photo: require('./assets/images/couple/1.jpeg'),
        status: 'PENGANTIN PRIA',
        socmed: {
          facebook: 'https://www.facebook.com/bahyusanciko/',
          twitter: 'https://x.com/bhysnck',
          instagram: 'https://www.instagram.com/bahyusanciko/',
        },
        description: 'Putra pertama dari Bapak Edi Chanigo (Alm) dan Ibu Mayasnida',
      },
      bride: {
        name: 'Sella Purwita Sari A.Md. Par.',
        photo: require('./assets/images/couple/2.jpeg'),
        status: 'PENGANTIN WANITA',
        socmed: {
          facebook: 'https://www.facebook.com/profile.php?id=100008708643415',
          twitter: 'https://x.com/sellaprwt',
          instagram: 'https://www.instagram.com/sellaprwt/',
        },
        description: 'Putri ketiga dari Bapak Taryono dan Ibu Rosmini (Almh)',
      },
    },
    events: {
      ceremony: {
        type: 'ceremony',
        foto: require('./assets/images/events/ceremony.jpg'),
        title: 'AKAD',
        date: 'Minggu, 3 November 2024',
        time: '15.00 - 18.00 WIB',
        address: {
          line1: 'Villa Srimaganti',
          line2: 'JL. Raya Pkp No.34, RT.2/RW.8, Klp. Dua Wetan',
          line3: 'Kec. Ciracas, Kota Jakarta Timur',
        },
      },
      reception: {
        type: 'reception',
        foto: require('./assets/images/events/reception.jpg'),
        title: 'RESEPSI',
        date: 'Minggu, 3 November 2024',
        time: '18.00 - 20.00 WIB',
        address: {
          line1: 'Villa Srimaganti',
          line2: 'JL. Raya Pkp No.34, RT.2/RW.8, Klp. Dua Wetan',
          line3: 'Kec. Ciracas, Kota Jakarta Timur',
        },
      },
    },
    moment: {
      images: [
        {
          src: require('./assets/images/moment/pt-0.jpg'),
          thumbnail: require('./assets/images/moment/pt-0.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-1.jpg'),
          thumbnail: require('./assets/images/moment/pt-1.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-2.jpg'),
          thumbnail: require('./assets/images/moment/pt-2.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-3.jpg'),
          thumbnail: require('./assets/images/moment/pt-3.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-4.jpg'),
          thumbnail: require('./assets/images/moment/pt-4.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-5.jpg'),
          thumbnail: require('./assets/images/moment/pt-5.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-6.jpg'),
          thumbnail: require('./assets/images/moment/pt-6.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-7.jpg'),
          thumbnail: require('./assets/images/moment/pt-7.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-8.jpg'),
          thumbnail: require('./assets/images/moment/pt-8.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-9.jpg'),
          thumbnail: require('./assets/images/moment/pt-9.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-11.jpg'),
          thumbnail: require('./assets/images/moment/pt-11.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-12.jpg'),
          thumbnail: require('./assets/images/moment/pt-12.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-13.jpg'),
          thumbnail: require('./assets/images/moment/pt-13.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-14.jpg'),
          thumbnail: require('./assets/images/moment/pt-14.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-15.jpg'),
          thumbnail: require('./assets/images/moment/pt-15.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-16.jpg'),
          thumbnail: require('./assets/images/moment/pt-16.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-17.jpg'),
          thumbnail: require('./assets/images/moment/pt-17.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/pt-18.jpg'),
          thumbnail: require('./assets/images/moment/pt-18.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/pt-19.jpg'),
          thumbnail: require('./assets/images/moment/pt-19.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
         {
          src: require('./assets/images/moment/pt-20.jpg'),
          thumbnail: require('./assets/images/moment/pt-20.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/ls-1.jpg'),
          thumbnail: require('./assets/images/moment/ls-1.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/ls-2.jpg'),
          thumbnail: require('./assets/images/moment/ls-2.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/ls-3.jpg'),
          thumbnail: require('./assets/images/moment/ls-3.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/ls-4.jpg'),
          thumbnail: require('./assets/images/moment/ls-4.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/ls-5.jpg'),
          thumbnail: require('./assets/images/moment/ls-5.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/ls-6.jpg'),
          thumbnail: require('./assets/images/moment/ls-6.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/ls-7.jpg'),
          thumbnail: require('./assets/images/moment/ls-7.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/ls-8.jpg'),
          thumbnail: require('./assets/images/moment/ls-8.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/ls-9.jpg'),
          thumbnail: require('./assets/images/moment/ls-9.jpg'),
          thumbnailWidth: 320,
          thumbnailHeight: 200,
        },
        {
          src: require('./assets/images/moment/ls-10.jpg'),
          thumbnail: require('./assets/images/moment/ls-10.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
        {
          src: require('./assets/images/moment/ls-11.jpg'),
          thumbnail: require('./assets/images/moment/ls-11.jpg'),
          thumbnailWidth: 240,
          thumbnailHeight: 320,
        },
      ],
    },
    location: {
      maps: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15861.643214787087!2d106.8865514!3d-6.3408067!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ece0f99a5e9b%3A0x168b992d3633c0cf!2sVilla%20Srimanganti!5e0!3m2!1sid!2sid!4v1723555123443!5m2!1sid!2sid',
    },
    gift:{
      title: 'Kami sangat berterima kasih atas kehadiran dan doa restu Anda. Jika Anda ingin mengirimkan hadiah, Anda dapat menggunakan tautan pembayaran atau kode QR berikut:',
      images: [
        {
          title: 'BCA - 5490252063 a/n Bahyu',
          src:require('./assets/images/gift/bahyu-bca.jpeg'),
        },
        {
          title: 'BCA - 1610026458 a/n Sella',
          src:require('./assets/images/gift/sella-bca.jpeg'),
        },
      ],
    }
  },
};
